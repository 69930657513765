<template>
  <v-dialog v-model="dialogOpen" max-width="640" persistent>
    <v-card>
      <h2 class="text-center py-5">
        {{ clientData.clientName }}
      </h2>
      <v-card-text v-if="planData && planData.serviceTasks">
        <div
          v-for="(task, index) in planData.serviceTasks"
          :key="index"
          class="py-1"
        >
          <h3>{{ index + 1 }}.{{ task.taskDescription }}</h3>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <custom-button
          @click="closeDialog"
          type="warningtext"
          :text="$t('btn.close')"
          icon="close"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomButton from "@/components/CustomButton.vue";
export default {
  components: { CustomButton },
  props: ["planData", "clientData", "dialogOpen"],
  data() {
    return {};
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style>
</style>