var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"640"},model:{value:(_vm.data.offDayDialog),callback:function ($$v) {_vm.$set(_vm.data, "offDayDialog", $$v)},expression:"data.offDayDialog"}},[(_vm.data.offDayDialog)?_c('v-card',[_c('v-card-title',{staticClass:"headline"},[(_vm.data.direction == 'out')?_c('span',[(
              _vm.$root.currentUserData.userId != 'OFWRdIOe24dvmu7WfQPzM4qapAf1'
            )?_c('v-icon',[_vm._v(" flight_takeoff ")]):_c('v-icon',[_vm._v("flight_land")]),_vm._v(" "+_vm._s(_vm.data.client.clientName)+" - "+_vm._s(_vm.$t("client.add-outing").toLowerCase())+" ")],1):_c('span',[(
              _vm.$root.currentUserData.userId != 'OFWRdIOe24dvmu7WfQPzM4qapAf1'
            )?_c('v-icon',[_vm._v(" flight_land ")]):_c('v-icon',[_vm._v("flight_takeoff")]),_vm._v(" "+_vm._s(_vm.data.client.clientName)+" - "+_vm._s(_vm.$t("client.add-return").toLowerCase())+" ")],1)]),_c('v-card-text',[_c('v-form',{ref:"offDayForm",attrs:{"lazy-validation":"","id":"offDayForm"},model:{value:(_vm.offDayFormValid),callback:function ($$v) {_vm.offDayFormValid=$$v},expression:"offDayFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.selectDirection,"label":_vm.$t('client.outing-or-arrival'),"disabled":!_vm.data.edit},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.text))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.text))+" ")]}}],null,false,3653205372),model:{value:(_vm.data.direction),callback:function ($$v) {_vm.$set(_vm.data, "direction", $$v)},expression:"data.direction"}})],1)],1),(_vm.data.direction == 'out')?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{attrs:{"close-on-content-click":false,"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":((_vm.$t('client.on-off-day')) + " - " + (_vm.$t(
                      'form.date'
                    ).toLowerCase())),"readonly":"","rules":[
                      function () { return !!_vm.formData.outDate || _vm.$t('form.validate-required'); } ]},model:{value:(_vm.outDateFormatted),callback:function ($$v) {_vm.outDateFormatted=$$v},expression:"outDateFormatted"}},on))]}}],null,false,3488681266),model:{value:(_vm.outDateDialog),callback:function ($$v) {_vm.outDateDialog=$$v},expression:"outDateDialog"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","locale":_vm.$i18n.locale},on:{"input":function($event){_vm.outDateDialog = false}},model:{value:(_vm.formData.outDate),callback:function ($$v) {_vm.$set(_vm.formData, "outDate", $$v)},expression:"formData.outDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('client.on-off-day')) + " - " + (_vm.$t(
                  'general.hours'
                ).toLowerCase())),"rules":_vm.hourRules,"type":"number"},model:{value:(_vm.formData.outHours),callback:function ($$v) {_vm.$set(_vm.formData, "outHours", $$v)},expression:"formData.outHours"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('client.on-off-day')) + " - " + (_vm.$t(
                  'general.minutes'
                ).toLowerCase())),"rules":_vm.minutesRules,"type":"number"},model:{value:(_vm.formData.outMinutes),callback:function ($$v) {_vm.$set(_vm.formData, "outMinutes", $$v)},expression:"formData.outMinutes"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('client.on-off-day')) + " - " + (_vm.$t(
                  'general.comment'
                ))),"rules":[
                  function () { return !!_vm.formData.outComment || _vm.$t('form.validate-required'); } ]},model:{value:(_vm.formData.outComment),callback:function ($$v) {_vm.$set(_vm.formData, "outComment", $$v)},expression:"formData.outComment"}})],1)],1):_vm._e(),(_vm.data.direction == 'return')?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{attrs:{"close-on-content-click":false,"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":((_vm.$t('client.arrive')) + " - " + (_vm.$t(
                      'form.date'
                    ).toLowerCase())),"readonly":"","rules":[
                      function () { return !!_vm.formData.returnDate || _vm.$t('form.validate-required'); } ]},model:{value:(_vm.returnDateFormatted),callback:function ($$v) {_vm.returnDateFormatted=$$v},expression:"returnDateFormatted"}},on))]}}],null,false,2995919611),model:{value:(_vm.returnDateDialog),callback:function ($$v) {_vm.returnDateDialog=$$v},expression:"returnDateDialog"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","min":_vm.data.client.latestOffDay.latestOutDate,"locale":_vm.$i18n.locale},on:{"input":function($event){_vm.returnDateDialog = false}},model:{value:(_vm.formData.returnDate),callback:function ($$v) {_vm.$set(_vm.formData, "returnDate", $$v)},expression:"formData.returnDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('client.arrive')) + " -  " + (_vm.$t(
                  'general.hours'
                ).toLowerCase())),"rules":_vm.hourRules,"type":"number"},model:{value:(_vm.formData.returnHours),callback:function ($$v) {_vm.$set(_vm.formData, "returnHours", $$v)},expression:"formData.returnHours"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('client.arrive')) + " - " + (_vm.$t(
                  'general.minutes'
                ).toLowerCase())),"rules":_vm.minutesRules,"type":"number"},model:{value:(_vm.formData.returnMinutes),callback:function ($$v) {_vm.$set(_vm.formData, "returnMinutes", $$v)},expression:"formData.returnMinutes"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('client.arrive')) + " - " + (_vm.$t(
                  'general.comment'
                ).toLowerCase()))},model:{value:(_vm.formData.returnComment),callback:function ($$v) {_vm.$set(_vm.formData, "returnComment", $$v)},expression:"formData.returnComment"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("clear")]),_vm._v(_vm._s(_vm.$t("btn.cancel"))+" ")],1),(_vm.data.direction != null)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.saveForm()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("save")]),_vm._v(_vm._s(_vm.$t("btn.save"))+" ")],1):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }