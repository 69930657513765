var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"640","persistent":""},model:{value:(_vm.incDialog),callback:function ($$v) {_vm.incDialog=$$v},expression:"incDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.clientData.clientName)+" ")]),_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[(_vm.clientData.clientPhotoUrl && !_vm.clientData.clientPhotoUrl == '')?_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":"100","color":"secondary"}},[_c('v-img',{attrs:{"src":_vm.clientData.clientPhotoUrl}})],1)],1):_vm._e()],1),_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("client.incident"))+" ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":"","id":"form"},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"close-on-content-click":false,"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":((_vm.$t('form.date')) + "*"),"prepend-icon":"event","readonly":"","rules":[
                      function () { return !!_vm.incidentData.dateFormatted ||
                        _vm.$t('form.validate-required'); } ]},model:{value:(_vm.incidentData.dateFormatted),callback:function ($$v) {_vm.$set(_vm.incidentData, "dateFormatted", $$v)},expression:"incidentData.dateFormatted"}},on))]}}]),model:{value:(_vm.datePickerDialog),callback:function ($$v) {_vm.datePickerDialog=$$v},expression:"datePickerDialog"}},[_c('v-date-picker',{attrs:{"max":_vm.today,"first-day-of-week":"1","locale":_vm.$i18n.locale},on:{"input":function($event){_vm.datePickerDialog = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('global.time')) + " - " + (_vm.$t('general.hour')) + "*"),"prepend-icon":"timer","rules":_vm.hourRules,"type":"number","step":"1"},model:{value:(_vm.incidentData.incHours),callback:function ($$v) {_vm.$set(_vm.incidentData, "incHours", $$v)},expression:"incidentData.incHours"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":((_vm.$t('global.time')) + " - " + (_vm.$t('general.minute')) + "*"),"prepend-icon":"timer","rules":_vm.minutesRules,"type":"number","step":"1"},model:{value:(_vm.incidentData.incMinutes),callback:function ($$v) {_vm.$set(_vm.incidentData, "incMinutes", $$v)},expression:"incidentData.incMinutes"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.incidents,"label":((_vm.$t('client.incident')) + "*"),"prepend-icon":"error","return-object":"","rules":[function () { return !!_vm.incident || _vm.$t('form.validate-required'); }]},model:{value:(_vm.incident),callback:function ($$v) {_vm.incident=$$v},expression:"incident"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":((_vm.$t('general.comment')) + "*"),"prepend-icon":"comment","rules":[
                  function () { return !!_vm.incidentData.comment || _vm.$t('form.validate-required'); } ]},model:{value:(_vm.incidentData.comment),callback:function ($$v) {_vm.$set(_vm.incidentData, "comment", $$v)},expression:"incidentData.comment"}})],1)],1),_c('v-row',[_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.photoDisplay)?_c('v-img',{staticClass:"incidentImage",attrs:{"src":_vm.photoDisplay}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.photoUrl == null)?_c('div',{staticClass:"photo-div"},[_c('label',[_c('v-icon',[_vm._v("insert_photo")]),_vm._v(_vm._s(_vm.$t("global.add-photo"))+": ")],1),_c('input',{attrs:{"type":"file","accept":"image/*","id":'photoFile'},on:{"change":function($event){return _vm.addPhoto()}}})]):_vm._e(),(_vm.photoUrl != null)?_c('div',{staticClass:"photo-div"},[_c('v-img',{attrs:{"src":_vm.photoUrl,"lazy-src":"https://via.placeholder.com/150","contain":""}}),_c('v-btn',{attrs:{"id":"deleteImageBtn","text":"","small":"","color":"red lighten-2"},on:{"click":function($event){return _vm.deletePhoto()}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("clear")]),_vm._v(_vm._s(_vm.$t("btn.delete"))+" ")],1)],1):_vm._e(),_c('br')])],1),(_vm.clientData.clientServices == 'bfh')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":((_vm.$t('global.resolved')) + ": " + (_vm.incidentData.incidentSolved
                    ? _vm.$t('global.yes')
                    : _vm.$t('global.no')))},model:{value:(_vm.incidentData.incidentSolved),callback:function ($$v) {_vm.$set(_vm.incidentData, "incidentSolved", $$v)},expression:"incidentData.incidentSolved"}})],1)],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addIncident()}}},[_c('v-icon',[_vm._v("save")]),_vm._v(_vm._s(_vm.$t("btn.save"))+" ")],1),_c('v-btn',{attrs:{"color":"error","text":"","outlined":""},on:{"click":function($event){return _vm.closeClientIncDialog()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("close")]),_vm._v(_vm._s(_vm.$t("btn.close"))+" ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }