<template>
  <v-row align="center" justify="center">
    <v-dialog v-model="data.offDayDialog" max-width="640">
      <v-card v-if="data.offDayDialog">
        <v-card-title class="headline">
          <span v-if="data.direction == 'out'">
            <v-icon
              v-if="
                $root.currentUserData.userId != 'OFWRdIOe24dvmu7WfQPzM4qapAf1'
              "
            >
              flight_takeoff
            </v-icon>
            <v-icon v-else>flight_land</v-icon>
            {{ data.client.clientName }} -
            {{ $t("client.add-outing").toLowerCase() }}
          </span>
          <span v-else>
            <v-icon
              v-if="
                $root.currentUserData.userId != 'OFWRdIOe24dvmu7WfQPzM4qapAf1'
              "
            >
              flight_land
            </v-icon>
            <v-icon v-else>flight_takeoff</v-icon>
            {{ data.client.clientName }} -
            {{ $t("client.add-return").toLowerCase() }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="offDayForm"
            v-model="offDayFormValid"
            lazy-validation
            id="offDayForm"
          >
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="selectDirection"
                  v-model="data.direction"
                  :label="$t('client.outing-or-arrival')"
                  :disabled="!data.edit"
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items  -->
                    {{ $t(data.item.text) }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row
              align="center"
              justify="center"
              v-if="data.direction == 'out'"
            >
              <v-col cols="12" md="6">
                <!-- Oute date -->
                <v-dialog
                  v-model="outDateDialog"
                  :close-on-content-click="false"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="outDateFormatted"
                      :label="`${$t('client.on-off-day')} - ${$t(
                        'form.date'
                      ).toLowerCase()}`"
                      readonly
                      v-on="on"
                      :rules="[
                        () =>
                          !!formData.outDate || $t('form.validate-required'),
                      ]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.outDate"
                    @input="outDateDialog = false"
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  ></v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  :label="`${$t('client.on-off-day')} - ${$t(
                    'general.hours'
                  ).toLowerCase()}`"
                  v-model="formData.outHours"
                  :rules="hourRules"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  :label="`${$t('client.on-off-day')} - ${$t(
                    'general.minutes'
                  ).toLowerCase()}`"
                  v-model="formData.outMinutes"
                  :rules="minutesRules"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="`${$t('client.on-off-day')} - ${$t(
                    'general.comment'
                  )}`"
                  v-model="formData.outComment"
                  :rules="[
                    () => !!formData.outComment || $t('form.validate-required'),
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              align="center"
              justify="center"
              v-if="data.direction == 'return'"
            >
              <v-col cols="12" md="6">
                <!-- Return date -->
                <v-dialog
                  v-model="returnDateDialog"
                  :close-on-content-click="false"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="returnDateFormatted"
                      :label="`${$t('client.arrive')} - ${$t(
                        'form.date'
                      ).toLowerCase()}`"
                      readonly
                      v-on="on"
                      :rules="[
                        () =>
                          !!formData.returnDate || $t('form.validate-required'),
                      ]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.returnDate"
                    @input="returnDateDialog = false"
                    first-day-of-week="1"
                    :min="data.client.latestOffDay.latestOutDate"
                    :locale="$i18n.locale"
                  ></v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  :label="`${$t('client.arrive')} -  ${$t(
                    'general.hours'
                  ).toLowerCase()}`"
                  v-model="formData.returnHours"
                  :rules="hourRules"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                  :label="`${$t('client.arrive')} - ${$t(
                    'general.minutes'
                  ).toLowerCase()}`"
                  v-model="formData.returnMinutes"
                  :rules="minutesRules"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :label="`${$t('client.arrive')} - ${$t(
                    'general.comment'
                  ).toLowerCase()}`"
                  v-model="formData.returnComment"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="secondary" text @click="closeForm()">
            <v-icon left>clear</v-icon>{{ $t("btn.cancel") }}
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="saveForm()"
            v-if="data.direction != null"
          >
            <v-icon left>save</v-icon>{{ $t("btn.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { db, auth } from "@/main";
import { formatDate } from "@/helpers/helperFns.js";
import { offDayDirections } from "@/helpers/constants.js";
import { hourRules, minutesRules } from "@/helpers/validations.js";

//item state mixin to show/hide freedback
import keepStateMixin from "@/mixins/itemState";

export default {
  props: ["data"],
  mixins: [keepStateMixin],
  data() {
    return {
      offDayFormValid: true,
      formData: {},
      outDateDialog: false,
      returnDateDialog: false,
      outDateFormatted: null,
      returnDateFormatted: null,

      //imports
      selectDirection: [],
      hourRules: [],
      minutesRules: [],
    };
  },
  created() {
    this.selectDirection = offDayDirections;
    this.hourRules = hourRules;
    this.minutesRules = minutesRules;
  },
  watch: {
    //format date
    "formData.outDate": function (val) {
      this.outDateFormatted = this.formatDate(val);
    },
    "formData.returnDate": function (val) {
      this.returnDateFormatted = this.formatDate(val);
    },
    data: function (val) {
      if (this.data.edit) {
        this.formData = Object.assign({}, this.data.formData);
      }
    },
  },
  methods: {
    showFeedback(status) {
      //emit state to task tile
      this.dispatch(
        "my-clients",
        "showHideFeedback",
        status,
        "Saglabā izbraukuma datus"
      );
    },
    closeForm() {
      this.offDayFormValid = true;
      this.formData = {};
      this.data.offDayDialog = false;
    },
    async saveForm() {
      var vm = this;

      if (this.$refs.offDayForm.validate()) {
        vm.showFeedback(true);

        //out or return
        if (vm.data.direction == "out") {
          //new off day
          const [year, month, day] = vm.formData.outDate.split("-");
          let oMonthKey = year + "-" + month;
          vm.formData["monthKeys"] = [oMonthKey];
          vm.formData["outDateRaw"] = new Date(
            year,
            month - 1,
            day,
            vm.formData.outHours,
            vm.formData.outMinutes,
            0
          );

          if (parseInt(vm.formData.outMinutes) < 10) {
            vm.formData.outMinutes = "0" + parseInt(vm.formData.outMinutes);
          }
          vm.formData["clientId"] = vm.data.clientId;
          vm.formData["clientName"] = vm.data.client.clientName;

          vm.formData["clientDistrict"] = vm.data.client.clientDistrict || null;
          vm.formData["clientDistrictName"] =
            vm.data.client.clientDistrictName || "NA";
          if (vm.data.client.clientDivision) {
            vm.formData["clientDivision"] = vm.data.client.clientDivision;
          }

          vm.formData["clientServices"] = vm.data.client.clientServices;
          vm.formData["outAddedBy"] = this.$root.currentUserData.displayName;

          vm.formData["outAddedAt"] = new Date();

          if (this.$root.aider) {
            vm.formData["lastAiderUid"] = auth.currentUser.uid;
            vm.formData["lastAiderDate"] = formatDate(new Date());
          }

          //check if out now
          if (!vm.formData.returnDate) {
            vm.formData["outNow"] = true;
          }

          //close dialog
          this.data.offDayDialog = false;

          let saveOffDay = await db
            .collection("clients")
            .doc(vm.data.clientId)
            .collection("offDays")
            .add(vm.formData);

          //update locally
          const latestOffDay = {
            latestOffDayId: saveOffDay.id,
            latestOutDate: vm.formData.outDate,
            outNow: true,
          };
          vm.updateOffDay(latestOffDay);
        } else if (vm.data.direction == "return") {
          let lastOffDayId = vm.data.client.latestOffDay.latestOffDayId;
          //returning
          //new off day
          const [year, month, day] = vm.formData.returnDate.split("-");
          vm.formData["returnDateRaw"] = new Date(
            year,
            month - 1,
            day,
            vm.formData.returnHours,
            vm.formData.returnMinutes,
            0
          );

          if (parseInt(vm.formData.returnMinutes) < 10) {
            vm.formData.returnMinutes =
              "0" + parseInt(vm.formData.returnMinutes);
          }
          vm.formData["clientId"] = vm.data.clientId;
          vm.formData["clientName"] = vm.data.client.clientName;
          vm.formData["returnAddedBy"] = this.$root.currentUserData.displayName;
          vm.formData["returnAddedAt"] = new Date();
          vm.formData["outNow"] = false;

          if (this.$root.aider) {
            vm.formData["lastAiderUid"] = auth.currentUser.uid;
            vm.formData["lastAiderDate"] = formatDate(new Date());
          }

          //close dialog
          this.data.offDayDialog = false;

          //save in db
          let saveOffDay = await db
            .collection("clients")
            .doc(vm.data.clientId)
            .collection("offDays")
            .doc(lastOffDayId)
            .update(vm.formData);

          //update locally
          const latestOffDay = {
            latestOffDayId: lastOffDayId,
            outNow: false,
          };
          vm.updateOffDay(latestOffDay);
        }

        //reset form

        vm.closeForm();
        vm.showFeedback(false);
      }
    },
    updateOffDay(latestOffDay) {
      this.dispatch(
        "my-clients",
        "updateOffDay",
        this.data.clientId,
        latestOffDay
      );
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
};
</script>
