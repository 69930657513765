<template>
  <div style="height: 40px">
    <!-- BUTTONS ADD OFFDAY OR RETUN -->
    <v-tooltip bottom v-if="showReturn(client)">
      <template v-slot:activator="{ on }">
        <v-btn
          @click.stop="registerOffDay('return', clientId, client, false)"
          small
          outlined
          fab
          v-on="on"
        >
          <v-icon
            v-if="
              $root.currentUserData.userId != 'OFWRdIOe24dvmu7WfQPzM4qapAf1'
            "
          >
            flight_land
          </v-icon>
          <v-icon v-else>flight_takeoff</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("client.add-return") }} </span>
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on }">
        <v-btn
          @click.stop="registerOffDay('out', clientId, client, false)"
          small
          outlined
          fab
          v-on="on"
        >
          <v-icon
            v-if="
              $root.currentUserData.userId != 'OFWRdIOe24dvmu7WfQPzM4qapAf1'
            "
          >
            flight_takeoff
          </v-icon>
          <v-icon v-else>flight_land</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("client.add-outing") }}</span>
    </v-tooltip>

    <!-- OFF DAY DIALOG -->
    <off-day-dialog
      :key="'off-day-dialog' + clientId"
      :data="offDayDialogData"
    ></off-day-dialog>
  </div>
</template>

<script>
import OffDayDialog from "@/views/myclients/OffDayDialog.vue";

export default {
  name: "AddOffDay",
  props: ["clientId", "client"],
  components: {
    OffDayDialog,
  },
  data() {
    return {
      offDayDialogData: {},
    };
  },
  methods: {
    registerOffDay(direction, clientId, client, edit) {
      let passData = {
        clientId: clientId,
        client: client,
        direction: direction,
        offDayDialog: true,
        edit: edit,
      };
      this.offDayDialogData = Object.assign({}, passData);
    },
    showReturn(clientData) {
      if (!clientData.latestOffDay) {
        return false;
      }

      if (clientData.latestOffDay.outNow == true) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
