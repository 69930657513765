var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"40px"}},[(_vm.showReturn(_vm.client))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","outlined":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.registerOffDay('return', _vm.clientId, _vm.client, false)}}},on),[(
            _vm.$root.currentUserData.userId != 'OFWRdIOe24dvmu7WfQPzM4qapAf1'
          )?_c('v-icon',[_vm._v(" flight_land ")]):_c('v-icon',[_vm._v("flight_takeoff")])],1)]}}],null,false,2763467349)},[_c('span',[_vm._v(_vm._s(_vm.$t("client.add-return"))+" ")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","outlined":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.registerOffDay('out', _vm.clientId, _vm.client, false)}}},on),[(
            _vm.$root.currentUserData.userId != 'OFWRdIOe24dvmu7WfQPzM4qapAf1'
          )?_c('v-icon',[_vm._v(" flight_takeoff ")]):_c('v-icon',[_vm._v("flight_land")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("client.add-outing")))])]),_c('off-day-dialog',{key:'off-day-dialog' + _vm.clientId,attrs:{"data":_vm.offDayDialogData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }