<template>
  <v-dialog v-model="clientInfoDialog" max-width="640" persistent>
    <v-card>
      <v-card-title class="headline">
        {{ clientData.clientName }}
      </v-card-title>

      <v-card-text>
        <v-list dense>
          <h3>{{ $t("client.basic-info") }}</h3>
          <v-list-item
            v-if="clientData.clientPhotoUrl && !clientData.clientPhotoUrl == ''"
          >
            <v-list-item-avatar size="100" color="secondary">
              <v-img :src="clientData.clientPhotoUrl"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item v-if="clientData.birthDate">
            <v-list-item-content>
              <span>
                <strong> {{ $t("client.date-of-birth") }}: </strong>
                {{ formatDate(clientData.birthDate) }}
              </span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="clientData.clientAddress">
            <v-list-item-content>
              <span>
                <strong> {{ $t("client.address") }}: </strong>
                {{ clientData.clientAddress }}
              </span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="clientData.clientDoorCode">
            <v-list-item-content>
              <span>
                <strong> {{ $t("client.door-code") }}: </strong>
                {{ clientData.clientDoorCode }}
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="clientData.clientContacts">
            <v-list-item-content>
              <span>
                <strong> {{ $t("client.contact-info") }}: </strong>
                {{ clientData.clientContacts }}
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="
              clientData.contactPersonName || clientData.contactPersonContacts
            "
          >
            <v-list-item-content>
              <span>
                <strong> {{ $t("client.contact-person") }}: </strong>
                {{ clientData.contactPersonName }}
                <span v-if="clientData.contactPersonContacts">
                  {{ $t("client.phone-short").toLowerCase() }}
                  {{ clientData.contactPersonContacts }}
                </span>
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="clientData.doctorName || clientData.doctorContacts"
          >
            <v-list-item-content>
              <span>
                <strong> {{ $t("client.family-doctor") }}: </strong>
                {{ clientData.doctorName }}
                <span v-if="clientData.doctorContacts">
                  {{ $t("client.phone-short").toLowerCase() }}
                  {{ clientData.doctorContacts }}
                </span>
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="clientData.clientDescription">
            <v-list-item-content>
              <span>
                <strong> {{ $t("client.other-info") }}: </strong>
                {{ clientData.clientDescription }}
              </span>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list v-if="Object.keys(clientIncidents).length > 0" dense>
          <h3>{{ $t("nav.incidents") }}</h3>
          <v-list-item v-for="(inc, incId) in clientIncidents" :key="incId">
            <v-list-item-content>
              <v-list-item-title>
                {{ inc.dateFormatted }} {{ getTranslatedIncident(inc) }}
              </v-list-item-title>
              <p>
                {{ inc.comment }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="closeClientInfoDialog()" outlined>
          <v-icon left>close</v-icon>{{ $t("btn.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from "@/main";
import { formatDate } from "@/helpers/helperFns.js";
import { subtractDaysIsoToIso } from "@/helpers/dayjsDates.js";
import { find, isNil, isEqual } from "lodash";
import { incidents } from "@/helpers/constants";

export default {
  name: "client-info",
  props: ["clientInfoDialog", "clientData", "clientId"],
  data() {
    return {
      clientIncidents: {},
      incidentsLoaded: false,
    };
  },
  watch: {
    clientInfoDialog: function () {
      if (this.incidentsLoaded === false) {
        this.loadIncidents();
      }
    },
  },
  methods: {
    getTranslatedIncident(incident) {
      const foundIncident = find(
        incidents,
        (inc) =>
          !isNil(inc.value) && isEqual(inc.value.trim(), incident.incident)
      );
      return isNil(foundIncident)
        ? incident.incident
        : this.$t(foundIncident.text);
    },
    async loadIncidents() {
      let vm = this;
      //only for last 14 days
      const todayKey = new Date().toISOString().substring(0, 10);
      const daysBefore = 14;
      const firstDayIso = subtractDaysIsoToIso(todayKey, daysBefore);
      let fromDate = new Date(firstDayIso);
      fromDate.setHours(0, 0, 0);

      let getIncidents = await db
        .collection("incidents")
        .where("date", ">=", fromDate)
        .where("clientId", "==", vm.clientId)
        .orderBy("date", "desc")
        .get();

      let data = {};
      getIncidents.forEach((doc) => {
        data[doc.id] = doc.data();
      });
      vm.clientIncidents = Object.assign({}, data);

      vm.incidentsLoaded = true;
    },
    closeClientInfoDialog() {
      this.$emit("closeClientInfoDialog");
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
};
</script>
