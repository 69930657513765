<template>
  <v-dialog v-model="healthDialog" max-width="640" persistent>
    <v-card>
      <v-card-title class="headline">
        {{ clientData.clientName }}
      </v-card-title>

      <v-card-text>
        <v-list dense>
          <v-list-item
            v-if="clientData.clientPhotoUrl && !clientData.clientPhotoUrl == ''"
          >
            <v-list-item-avatar size="100" color="secondary">
              <v-img :src="clientData.clientPhotoUrl"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <!-- data input -->
        <v-card>
          <v-card-title class="headline">
            {{ $t("general.health-data") }}
          </v-card-title>

          <v-card-subtitle>
            <v-row>
              <v-col cols="12">
                <v-btn-toggle v-model="btnToggle" dark mandatory>
                  <v-btn small color="accent" @click="emergency = false">
                    {{ $t("general.health-data") }}
                  </v-btn>
                  <v-btn small color="accent" @click="emergency = true">
                    {{ $t("general.emergency") }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-card-subtitle>

          <v-card-text>
            <!-- data input -->
            <v-layout row wrap>
              <v-flex xs12 md6 lg4>
                <!-- date -->
                <v-dialog
                  v-model="datePickerDialog"
                  :close-on-content-click="false"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dateFormatted"
                      :label="`${$t('form.date')}*`"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="datePickerDialog = false"
                    :max="today"
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  ></v-date-picker>
                </v-dialog>
              </v-flex>

              <!-- vitals -->
              <template v-if="!emergency">
                <v-flex
                  xs12
                  md6
                  lg4
                  v-for="(vit, vitId) in vitals"
                  :key="vitId"
                >
                  <v-text-field
                    type="number"
                    step="0.01"
                    v-model="vitals[vitId]"
                    :label="$t(`client.health-record-data.${vitId}`)"
                    prepend-icon="local_hospital"
                  ></v-text-field>
                </v-flex>
                <!-- comment box -->
                <v-flex xs12 md6 lg4>
                  <v-text-field
                    v-model="comment"
                    :label="$t('general.comment')"
                    prepend-icon="comment"
                  ></v-text-field>
                </v-flex>
              </template>
              <v-flex xs12 md12 lg12 v-if="emergency">
                <v-text-field
                  v-model="comment"
                  :label="$t('general.comment')"
                  prepend-icon="comment"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-card-text>

      <!-- save -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="saveVitals()">
          <v-icon>save</v-icon>{{ $t("btn.save") }}
        </v-btn>
        <v-btn color="error" text @click="closeClientHealthDialog()" outlined>
          <v-icon left>close</v-icon>{{ $t("btn.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db, auth } from "@/main";
import { formatDate } from "@/helpers/helperFns.js";
import { vitals } from "@/helpers/constants.js";

//item state mixin to show/hide freedback
import keepStateMixin from "@/mixins/itemState";

export default {
  name: "add-health",
  props: ["healthDialog", "clientData", "clientId"],
  mixins: [keepStateMixin],
  data() {
    return {
      btnToggle: 0,
      emergency: false,
      comment: "",
      today: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      datePickerDialog: false,
      vitals: {},
    };
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  created() {
    this.vitals = Object.assign({}, vitals);
  },
  methods: {
    restet() {
      this.btnToggle = 0;
      this.emergency = false;
      this.comment = "";
      this.today = new Date().toISOString().substr(0, 10);
      this.date = new Date().toISOString().substr(0, 10);

      this.datePickerDialog = false;
      this.vitals = Object.assign({}, vitals);
    },
    async saveVitals() {
      let vm = this;
      //verify if all required fields are filled
      vm.showFeedback(true);
      let dataObj = {
        clientName: vm.clientData.clientName,
        clientId: vm.clientId,
        clientServices: vm.clientData.clientServices,
        date: new Date(vm.date),
        dateFormatted: vm.dateFormatted,
        vitals: this.emergency ? null : vm.vitals,
        comment: vm.comment,
        emergency: this.emergency,
        monthKey: new Date(vm.date).toISOString().substr(0, 7),
      };

      //add new incident
      dataObj["createdAt"] = new Date();
      dataObj["createdAtFormatted"] = this.formatDate(this.today);
      dataObj["createdBy"] = this.$root.currentUserData.displayName;
      dataObj["createdById"] = auth.currentUser.uid;

      //add to db
      await db.collection("bodyVitals").add(dataObj);
      vm.closeClientHealthDialog();
      vm.showFeedback(false);

      //update list
      this.dispatch("my-clients", "updateHealth");
    },
    showFeedback(status) {
      //emit state to task tile
      this.dispatch(
        "my-clients",
        "showHideFeedback",
        status,
        "Saglabā veselības datus"
      );
    },
    closeClientHealthDialog() {
      //reset
      this.restet();
      this.$emit("closeClientHealthDialog");
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
};
</script>
