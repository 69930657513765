<template>
  <v-dialog v-model="incDialog" max-width="640" persistent>
    <v-card>
      <v-card-title class="headline">
        {{ clientData.clientName }}
      </v-card-title>

      <v-card-text>
        <v-list dense>
          <v-list-item
            v-if="clientData.clientPhotoUrl && !clientData.clientPhotoUrl == ''"
          >
            <v-list-item-avatar size="100" color="secondary">
              <v-img :src="clientData.clientPhotoUrl"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>

        <!-- data input -->
        <v-card-title class="headline">
          {{ $t("client.incident") }}
        </v-card-title>
        <v-row justify="center">
          <v-form ref="form" v-model="formValid" lazy-validation id="form">
            <v-row>
              <v-col cols="12">
                <!-- date -->
                <v-dialog
                  v-model="datePickerDialog"
                  :close-on-content-click="false"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="incidentData.dateFormatted"
                      :label="`${$t('form.date')}*`"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                      :rules="[
                        () =>
                          !!incidentData.dateFormatted ||
                          $t('form.validate-required'),
                      ]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="datePickerDialog = false"
                    :max="today"
                    first-day-of-week="1"
                    :locale="$i18n.locale"
                  ></v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="incidentData.incHours"
                  :label="`${$t('global.time')} - ${$t('general.hour')}*`"
                  prepend-icon="timer"
                  :rules="hourRules"
                  type="number"
                  step="1"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="incidentData.incMinutes"
                  :label="`${$t('global.time')} - ${$t('general.minute')}*`"
                  prepend-icon="timer"
                  :rules="minutesRules"
                  type="number"
                  step="1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <!-- select incident -->
                <v-select
                  v-model="incident"
                  :items="incidents"
                  :label="`${$t('client.incident')}*`"
                  prepend-icon="error"
                  return-object
                  :rules="[() => !!incident || $t('form.validate-required')]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <!-- comment box -->
                <v-textarea
                  v-model="incidentData.comment"
                  :label="`${$t('general.comment')}*`"
                  prepend-icon="comment"
                  :rules="[
                    () =>
                      !!incidentData.comment || $t('form.validate-required'),
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- foto -->
            <v-row>
              <v-container>
                <v-row align="center" justify="center">
                  <v-img
                    v-if="photoDisplay"
                    :src="photoDisplay"
                    class="incidentImage"
                  ></v-img>
                </v-row>
              </v-container>

              <v-col cols="12">
                <div class="photo-div" v-if="photoUrl == null">
                  <label>
                    <v-icon>insert_photo</v-icon>{{ $t("global.add-photo") }}:
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    :id="'photoFile'"
                    @change="addPhoto()"
                  />
                </div>
                <div v-if="photoUrl != null" class="photo-div">
                  <v-img
                    :src="photoUrl"
                    lazy-src="https://via.placeholder.com/150"
                    contain
                  ></v-img>
                  <v-btn
                    id="deleteImageBtn"
                    text
                    small
                    color="red lighten-2"
                    @click="deletePhoto()"
                  >
                    <v-icon dark left>clear</v-icon>{{ $t("btn.delete") }}
                  </v-btn>
                </div>
                <br />
              </v-col>
            </v-row>

            <!-- check box -->
            <v-row v-if="clientData.clientServices == 'bfh'">
              <v-col cols="12">
                <v-checkbox
                  v-model="incidentData.incidentSolved"
                  :label="`${$t('global.resolved')}: ${
                    incidentData.incidentSolved
                      ? $t('global.yes')
                      : $t('global.no')
                  }`"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-row>
      </v-card-text>

      <!-- save -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="addIncident()">
          <v-icon>save</v-icon>{{ $t("btn.save") }}
        </v-btn>
        <v-btn color="error" text @click="closeClientIncDialog()" outlined>
          <v-icon left>close</v-icon>{{ $t("btn.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db, storage } from "@/main";
import { formatDate } from "@/helpers/helperFns.js";
import { incidents } from "@/helpers/constants.js";
import { hourRules, minutesRules } from "@/helpers/validations.js";
import { isEmpty } from "lodash";
//item state mixin to show/hide freedback
import keepStateMixin from "@/mixins/itemState";

export default {
  name: "add-incident",
  props: ["incDialog", "clientData", "clientId"],
  mixins: [keepStateMixin],
  data() {
    return {
      incidentData: {},
      incId: null,
      today: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10),
      datePickerDialog: false,
      incident: null,
      formValid: true,
      photoUrl: null,
      //imports
      incidents: [],
      hourRules: [],
      minutesRules: [],
      photoDisplay: null,
    };
  },
  watch: {
    date: {
      handler(val) {
        this.incidentData.dateFormatted = formatDate(new Date(this.date));
        this.incidentData.date = new Date(this.date);
        this.incidentData.monthKey = new Date(this.date)
          .toISOString()
          .substring(0, 7);
      },
      immediate: true,
    },
  },
  created() {
    this.incidents = incidents;
    this.hourRules = hourRules;
    this.minutesRules = minutesRules;
  },
  methods: {
    addPhoto() {
      var fileInput = document.getElementById("photoFile");
      if (fileInput.files && !isEmpty(fileInput.files)) {
        var file = fileInput.files[0];
        this.photoDisplay = URL.createObjectURL(file);
      } else {
        this.photoDisplay = null;
      }
    },
    showFeedback(status) {
      //emit state to task tile
      this.dispatch(
        "my-clients",
        "showHideFeedback",
        status,
        "Saglabā incidentu"
      );
    },
    async addIncident() {
      var vm = this;

      //verify if all required fields are filled
      if (this.$refs.form.validate()) {
        vm.showFeedback(true);
        vm.incidentData.incMinutes =
          parseInt(vm.incidentData.incMinutes) < 10
            ? "0" + parseInt(vm.incidentData.incMinutes)
            : vm.incidentData.incMinutes;

        var incObj = {
          clientName: vm.clientData.clientName,
          clientId: vm.clientId,
          clientDistrict: vm.clientData.clientDistrict,
          clientDistrictName: vm.clientData.clientDistrictName,
          incidentStatus: "unconfirmed",

          incidentCognitive: vm.incident.cognitive,
          incident: vm.incident.value,

          alertAiders: vm.incident.alertAiders,

          clientServices: vm.clientData.clientServices,
          photoUrl: vm.photoUrl,
        };

        //merge with form data
        incObj = Object.assign(incObj, vm.incidentData);

        //add new incident
        incObj["createdAt"] = new Date();
        incObj["createdAtFormatted"] = this.formatDate(this.today);
        incObj["createdBy"] = this.$root.currentUserData.userId;
        incObj["user"] = this.$root.currentUserData.displayName;

        //add to db
        let docRef = await db.collection("incidents").add(incObj);

        //update list
        this.dispatch("my-clients", "updateIncidents");
        //save file incident photo
        vm.incId = docRef.id;
        vm.saveFile();

        vm.closeClientIncDialog();
        vm.showFeedback(false);
      }
    },
    async saveFile() {
      try {
        //get references
        var vm = this;
        //calculate ids
        var inputId = "photoFile";
        //get file
        var fileButton = document.getElementById(inputId);
        if (fileButton != null) {
          var file = fileButton.files[0];
          if (typeof file != "undefined") {
            var filePath = "/incidents/" + vm.incId + "/photoUrl/" + file.name;

            var storageRef = storage.ref(filePath);

            //upload file
            var uploadTask = await storageRef.put(file);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, //end save file
    closeClientIncDialog() {
      this.incidentData = Object.assign({}, {});

      this.$emit("closeClientIncDialog");
    },
    formatDate(date) {
      return formatDate(date);
    },
  },
};
</script>
<style>
.incidentImage {
  max-width: 300px;
  height: auto;
}
</style>
